<template>
  <div class="dataCenter">
<!--    <div class="title">
      <div class="block">
        <span class="demonstration" style="margin-right:2vh;font-size:1.4vh"
          >起止时间</span
        >
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['12:00:00']"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="deviceTime"
        >
        </el-date-picker>
      </div>
    </div>-->
    <div class="sideConter">
      <div class="treeLeft">
        <el-tree
          :data="leftList"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div class="echartsRight">
<!--        <div class="operation">
          <span @click="switchr('line')">折线图</span>
          <span @click="switchr('bar')">柱状图</span>
        </div>-->
        <div v-if="lineState" class="echartsBox" id="echartsId"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  data() {
    return {
      value1: "",
      leftList: [],
      defaultProps: {
        children: "children",
        label: "deviceName"
      },
      lineState: true,
      deviceNumber: null,
      dateArr: []
    };
  },
  mounted() {
    this.requestDevice();
  },
  methods: {
    handleNodeClick(a) {
      this.deviceNumber = a.deviceNum;
      if (this.value1) {
        this.deviceData(a.deviceNum, this.value1[0], this.value1[1]);
      } else {
        this.deviceData(a.deviceNum, this.getDateStr(-7), this.getDateStr(0));
      }
    },
    //
    disposeEcharts(){
      let echarts = this.$echarts.init(document.getElementById("echartsId"));
      echarts.dispose();
    },
    requestDevice() {
      this.$get("/deviceManage/getDeviceInfos", { page: 1, limit: 10000 }).then(
        res => {
          if (res.data.state == "success") {
            this.leftList = res.data.datas;
            //console.log(this.leftList,'+++++++++++++++++++++++')
            if (this.data.length != 0) {
              this.deviceNumber = this.leftList[0].deviceNum;
              let staTime ,endTime;
              if(this.value1){
                staTime = this.value1[0];
                endTime = this.value1[1];
                let s = staTime.split(" ");
                let e = endTime.split(" ");
                this.dateArr = this.getdiffdate(s[0], e[0]);
                //去掉数组最后一个  因为缺少数据
                this.dateArr.splice(this.dateArr.length-1, 1);
              }else{
                staTime =  this.getDateStr(-7);
                endTime = this.getDateStr(0);
                let s = staTime.split(" ");
                let e = endTime.split(" ");
                this.dateArr = this.getdiffdate(s[0], e[0]);
                this.dateArr.splice(0, 2);
              }
              this.deviceData(
                this.deviceNumber,
                staTime,
                endTime
              );
            }
          }
        }
      );
    },
    switchr(value) {
      // this.disposeEcharts();
      if (value == "line") {
        this.$echarts.init(document.getElementById("echartsId2")).dispose();
        this.lineState = true;
      }
      if (value == "bar") {
        this.$echarts.init(document.getElementById("echartsId")).dispose();
        this.lineState = false;
      }
      this.requestDevice();
    },
    deviceData(deviceNumber, startTime, endTime) {
      this.$get("/dptj/cgqqx4day", {
        deviceNum:deviceNumber,
        startYm: startTime,
        endYm: endTime
      }).then(res => {
        if (res.data.state="success") {
          let datas = res.data.data;
          if(datas.length != 0){
            const seriesData =  datas.map(v=>{
              if(v.sensorName!="null"){
                return {
                  type: 'line',
                  data: v.values,
                  name:v.sensorName,
                  smooth: true,
                  yAxisIndex: 0,
                  symbol: 'circle',
                  symbolSize: 6,
                  showSymbol:false,
                  hoverAnimation:true,
                }
              }

            })
            this.getEcharts(datas[0].times, seriesData)
          }
        }
      });
    },
    getEcharts(times, seriesData){
      var chartDom = document.getElementById("echartsId");
      var myChart = echarts.init(chartDom);
      let option = {
        color: ['#429CB7','#FAC37F','#22C888','#7586D9','#3eb4ff',"#edf249"],   // 折线颜色可以自定义
        grid:{
          // containLabel: true,
          left: 50,
          top: 30,
          bottom: 20,
          right: 30
        },
        legend: {
          // bottom: "5%",
          // align: 'left',
          // right: 0,
          // orient: 'vertical',
          textStyle:{
            color: '#000'
          },
          show:true
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          data: times,
          axisLine: {
            lineStyle: {
              color: '#fff',
              type:'dashed'//虚线
            }
          },
          axisTick: {
            show: false
          },

        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7',
              type:'dashed'//虚线
            }
          }
        }],
        series: seriesData
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    deviceTime(time) {
      this.deviceData(this.deviceNumber, time[0], time[1]);
      this.dateArr = this.getdiffdate(time[0].substring(0, 10), time[1].substring(0, 11));
      this.dateArr.splice(this.dateArr.length-1, 1);
    },
    getDateStr(AddDayCount) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1; //获取当前月份的日期，不足10补0
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(); //获取当前几号，不足10补0
      return y  + m + d;
    },
    //获取区间所有日期
    getdiffdate(stime, etime){
      //初始化日期列表，数组
      var diffdate = new Array();
      var i=0;
      //开始日期小于等于结束日期,并循环
      while(stime<=etime){
          diffdate[i] = stime;
          
          //获取开始日期时间戳
          var stime_ts = new Date(stime).getTime();
          //console.log('当前日期：'+stime   +'当前时间戳：'+stime_ts);
          
          //增加一天时间戳后的日期
          var next_date = stime_ts + (24*60*60*1000);
          
          //拼接年月日，这里的月份会返回（0-11），所以要+1
          var next_dates_y = new Date(next_date).getFullYear()+'-';
          var next_dates_m = (new Date(next_date).getMonth()+1 < 10)?'0'+(new Date(next_date).getMonth()+1)+'-':(new Date(next_date).getMonth()+1)+'-';
          var next_dates_d = (new Date(next_date).getDate() < 10)?'0'+new Date(next_date).getDate():new Date(next_date).getDate();
  
          stime = next_dates_y+next_dates_m+next_dates_d;
          
          //增加数组key
          i++;
      }
      return diffdate;
    }
  }
};
</script>

<style lang="less" scoped>
.dataCenter {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 2vh 1vh 1vh 1vh;
  box-sizing: border-box;
}
.dataCenter {
  /deep/ .el-input__inner {
    border: 1px solid #409eff;
  }
}
.sideConter {
  display: flex;
  height: 90%;
  margin-top: 2vh;
  .treeLeft {
    width: 10vw;
    // border: 1px solid green;
  }
  .echartsRight {
    flex: 1;
    position: relative;
    .operation {
      position: absolute;
      top: -5vh;
      right: 5vh;
      z-index: 2;
      span {
        cursor: pointer;
        font-size: 1.2vh;
        padding: 3px 6px;
        box-sizing: border-box;
        border: 1px solid #409eff;
        border-radius: 6px;
        margin-left: 10px;
      }
    }
    // border: 1px solid red;
  }
}
.treeLeft /deep/ .el-tree {
  height: 80vh;
  overflow-y: auto;
}
.treeLeft /deep/ .el-tree-node__content {
  background: #eee6;
  margin-bottom: 1vh;
}
.treeLeft /deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #8bc34a;
}
#echartsId,
#echartsId2 {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1500px) {
  .dataCenter {
    /deep/ .el-date-editor .el-range__icon {
      line-height: 4vh;
    }
    /deep/ .el-date-editor .el-range-separator {
      line-height: 4vh;
    }
    /deep/ .el-input__inner {
      height: 5vh;
      line-height: 5vh;
    }
  }
}
</style>
